<template>
  <div class="change-phone-info">
    <section-title show-asterisk>旧机信息</section-title>

    <field-piker label="iPhone型号" title="选择旧机型号" v-model="form.oldModel" placeholder="请选择旧机型号"
                 :columns="modelList" value-key="model_name" @getMessage="oldModelChange"></field-piker>

    <field-piker label="旧机存储" title="选择旧机存储" v-model="form.oldRAM" placeholder="请选择旧机存储"
                 :columns="oldRAMList" value-key="ram_size" toast-without-data="请先选择旧机机型"
                 @getMessage="oldRamChange"></field-piker>

    <field-piker label="旧机颜色" title="选择旧机颜色" v-model="form.oldColor" placeholder="请选择旧机颜色"
                 :columns="oldColorList" value-key="colour_name"
                 :toast-without-data="!form.oldModel?'请先选择旧机机型':!form.oldRAM?'请先选择旧机存储':''"
                 @getMessage="oldColorChange"></field-piker>

    <money title="旧机价格" disabled placeholder="自动生成" v-model="oldMoney" color="#333"></money>

    <section-title show-asterisk>新机信息</section-title>

    <field-piker label-width="12em" label="新iPhone型号" title="选择新机型号" v-model="form.newModel" placeholder="请选择新机型号"
                 :columns="modelList2" value-key="model_name"></field-piker>

    <field-piker label="新机存储" title="选择新机存储" v-model="form.newRAM" placeholder="请选择新机存储"
                 :columns="newRAMList" value-key="ram_size" toast-without-data="请先选择新机机型"></field-piker>

    <field-piker label="新机颜色" title="选择新机颜色" v-model="form.newColor" placeholder="请选择新机颜色"
                 :columns="newColorList" value-key="colour_name"
                 :toast-without-data="!form.newModel?'请先选择新机机型':!form.newRAM?'请先选择新机存储':''"></field-piker>

    <money title="新机价格" :need-symbol="true" disabled placeholder="自动生成" v-model="newMoney" color="#333"></money>

    <!--    <money title="焕新价格" :need-symbol="true" disabled placeholder="请输入焕新价格" v-model="changeMoney"-->
    <!--           color="#F20C00"></money>-->

    <!--    <money-display-with-notice title="旧机抵扣金额" v-model="deduct_price"-->
    <!--                               notice="旧机抵扣金额=旧机价格-5折焕新价"></money-display-with-notice>-->

    <money-display-with-notice v-if="isNeedIterationPrice" title="迭代补差金额" v-model="diff_money"
                               notice="迭代补差金额=新机价格-旧机价格"></money-display-with-notice>

    <section-title>焕新价格</section-title>
    <money-display-with-notice title="焕新价格" v-model="money"></money-display-with-notice>
    <!--                               notice="焕新价(用户补缴) =新机价格-旧机价格+5折换新价"></money-display-with-notice>-->

    <my-button class="change-btn" :class="{disabled:!isDone}" @click="confirm">进行焕新</my-button>

  </div>
</template>

<script>
import {phoneAfficialPrice, phoneKsii, phonePriceColour, phonePriceModel, phonePriceModel2, phonePriceRam} from "@/api";

export default {
  name: "index",
  data() {
    return {
      form: {
        oldModel: '',
        newModel: '',
        oldRAM: '',
        newRAM: '',
        oldColor: '',
        newColor: '',
      },
      oldMoney: '',
      newMoney: '',
      money: '',
      deduct_price: '',
      diff_money: '',
      modelList: [],
      modelList2: [],
      oldRAMList: [],
      newRAMList: [],
      oldColorList: [],
      newColorList: [],
      isShowProtocol: false,
      isDone: false,  //是否完成必填项
      errMsg: '', //查询新机价格错误信息
      formSerialNumber: false, //是否是完善信息页面的序列号查询返回数据
    }
  },
  mounted() {
    this.getModelList()
  },
  computed: {
    isNewAndOldRelate() {
      if (this.$store.state.config) {
        return this.$store.state.config.is_auto
      }
      return 1
    },
    //是否需要迭代价格
    isNeedIterationPrice() {
      if (this.$store.state.config) {
        return this.$store.state.config.is_iter_show
      }
      return 1
    }
  },
  watch: {
    "$route": {
      handler($route) {
        if ($route.path !== '/changePhoneInfo') {
          return
        }
        this.formSerialNumber = true
        let phoneInfo = this.$store.state.phoneInfo;
        this.modelList.forEach(item => {
          if (item.model_name === phoneInfo.oldModel) {
            this.form.oldModel = item
          }
        })

        this.getRAMList(phoneInfo.oldModel, 'oldRAMList').then(() => {
          this.oldRAMList.forEach(item => {
            if (item.ram_size === phoneInfo.oldRAM) {
              this.form.oldRAM = item
            }
          })
          //等到Ram列表获取到后再请求颜色列表
          this.getColorList(phoneInfo.oldModel, phoneInfo.oldRAM, 'oldColorList').then(() => {
            this.oldColorList.forEach(item => {
              if (item.colour_name === phoneInfo.oldColor) {
                this.form.oldColor = item
              }
            })
          })
        })

        this.oldMoney = phoneInfo.oldMoney
        this.money = phoneInfo.money
        this.deduct_price = phoneInfo.deduct_price
        this.diff_money = phoneInfo.diff_money
      },
      deep: true
    },
    form: {
      handler() {
        this.checkIfItSDone()
      },
      deep: true
    },
    'form.oldModel'(oldModel) {
      this.form.oldRAM = ''
      this.form.oldColor = ''

      this.oldRAMList = []
      this.oldColorList = []

      this.getRAMList(oldModel.model_name, 'oldRAMList')

      // if (!this.form.newModel) {
      // }
      if (!this.formSerialNumber && this.isNewAndOldRelate) {
        if (this.modelList2.find(item => {
          return item.model_name === oldModel.model_name
        })) {
          this.form.newModel = oldModel;
        }
      }

      this.getPriceDifferences();
    },
    'form.newModel'(newModel) {
      this.form.newRAM = ''
      this.form.newColor = ''

      this.newRAMList = []
      this.newColorList = []

      if (newModel) {
        this.getRAMList(newModel.model_name, 'newRAMList');
      }

      this.getPriceDifferences()
    },
    'form.oldRAM'(oldRAM) {
      this.form.oldColor = ''

      this.oldColorList = []

      if (oldRAM) {
        this.getColorList(this.form.oldModel.model_name, oldRAM.ram_size, 'oldColorList')
      }

      // if (this.form.newModel.model_name === this.form.oldModel.model_name && !this.form.newRAM) {
      if (this.form.newModel.model_name === this.form.oldModel.model_name) {
        if (!this.formSerialNumber && this.isNewAndOldRelate) {
          this.form.newRAM = oldRAM
        }
      }


      this.getPriceDifferences()
    },
    'form.newRAM'(newRAM) {
      this.form.newColor = ''

      this.newColorList = []

      this.getColorList(this.form.newModel.model_name, newRAM.ram_size, 'newColorList')

      this.getPriceDifferences()
    },
    'form.oldColor'(oldColor) {
      // if (this.form.newModel.model_name === this.form.oldModel.model_name &&
      //     this.form.newRAM.ram_size === this.form.oldRAM.ram_size && !this.form.newColor) {
      if (this.form.newModel.model_name === this.form.oldModel.model_name &&
          this.form.newRAM.ram_size === this.form.oldRAM.ram_size) {
        if (!this.formSerialNumber && this.isNewAndOldRelate) {
          this.form.newColor = oldColor
        }
      }

      this.getOfficialPrice(this.form.oldModel.model_name, this.form.oldRAM.ram_size, oldColor.colour_name, 'oldMoney')

      this.getPriceDifferences()
    },
    'form.newColor'(newColor) {
      this.getOfficialPrice(this.form.newModel.model_name, this.form.newRAM.ram_size, newColor.colour_name, 'newMoney', 1)

      this.getPriceDifferences()
    }
  },
  methods: {
    getModelList() {
      phonePriceModel({}).then(res => {
        this.modelList = res.data || []
      })
      phonePriceModel2({
        pass_id: this.$store.state.config.pass_id
      }).then(res => {
        this.modelList2 = res.data || []
      })
    },
    getRAMList(model_name, listName) {
      return phonePriceRam({
        model_name
      }).then(res => {
        this[listName] = res.data || []
      })
    },
    getColorList(model_name, ram_size, listName) {
      return phonePriceColour({model_name, ram_size}).then(res => {
        this[listName] = res.data || []
      })
    },
    getOfficialPrice(model_name, ram_size, colour_name, priceName, is_sell = 0) {
      if (!model_name || !colour_name || !ram_size) {
        return
      }
      phoneAfficialPrice({
        model_name, colour_name, ram_size,
        pass_id: this.$store.state.config.pass_id,
        is_sell
      }).then(res => {
        this[priceName] = res.data && res.data.official_price || ''
        if (is_sell === 1) {
          this.errMsg = ''
          this.checkIfItSDone()
        }
      }).catch(err => {
        this[priceName] = ''

        if (is_sell === 1) {
          let errMsg = err && err.msg || '暂无此新机价格'
          setTimeout(() => {
            this.errMsg = errMsg
            this.checkIfItSDone()
            this.$toast(errMsg)
          }, 100)
        }

      });
    },
    getPriceDifferences() {
      if (!this.form.oldModel || !this.form.oldRAM || !this.form.oldColor || !this.form.newModel || !this.form.newColor || !this.form.newRAM) {
        this.deduct_price = ''
        this.diff_money = ''
        this.money = ''
        return
      }
      phoneKsii({
        old_model: this.form.oldModel.model_name,
        old_ram: this.form.oldRAM.ram_size,
        old_colour: this.form.oldColor.colour_name,
        new_model: this.form.newModel.model_name,
        new_colour: this.form.newColor.colour_name,
        new_ram: this.form.newRAM.ram_size,
        pass_id: this.$store.state.config.pass_id
      }).then(res => {
        this.deduct_price = res.data.deduct_price || 0
        this.diff_money = res.data.diff_money || 0
        this.money = res.data.money || 0
      })
    },
    checkIfItSDone() {
      let unDone = ''
      for (const key in this.form) {
        let value = this.form[key]

        if (typeof value !== "boolean") {
          if (!value || (value.trim && !value.trim)) {
            unDone = key
            break
          }
        }
        if (typeof value === 'object') {
          if (JSON.stringify(value) === '{}' || JSON.stringify(value) === '[]') {
            unDone = key
            break
          }
        }
      }
      if (this.errMsg) {
        unDone = 'err'
      }
      this.isDone = !unDone;
      return unDone
    },
    oldModelChange() {
      this.formSerialNumber = false
    },
    oldRamChange() {
      this.formSerialNumber = false
    },
    oldColorChange() {
      this.formSerialNumber = false
    },
    confirm() {
      if (!this.isDone) {
        let errors = {
          oldModel: '请选择旧机型',
          newModel: '请选择新机型',
          oldRAM: '请选择旧机存储',
          newRAM: '请选择新机存储',
          newColor: '请选择新机颜色',
          err: this.errMsg
        };
        this.$toast(errors[this.checkIfItSDone()]);
        return;
      }
      let form = this.form
      let phoneInfo = {};
      phoneInfo.oldModel = form.oldModel.model_name
      phoneInfo.newModel = form.newModel.model_name
      phoneInfo.oldRAM = form.oldRAM.ram_size
      phoneInfo.newRAM = form.newRAM.ram_size
      phoneInfo.oldColor = form.oldColor.colour_name
      phoneInfo.newColor = form.newColor.colour_name
      phoneInfo.oldMoney = this.oldMoney
      phoneInfo.newMoney = this.newMoney
      phoneInfo.money = this.money
      phoneInfo.deduct_price = this.deduct_price
      phoneInfo.diff_money = this.diff_money

      this.$store.commit('setPhoneInfo', phoneInfo)
      this.$router.push({
        path: 'reservationInfo'
      })
    }
  }
}
</script>

<style scoped lang="less">
.change-phone-info {
  width: 100vw;
  min-height: 100vh;
  background: #F6F6F6;
  padding-bottom: 200px;
}

.protocol {
  span {
    color: #999;
  }

  .red {
    color: @base-color;
  }
}

.change-btn {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 60px);
}

.protocol-wrapper {
  padding: 20px;
  font-size: 28px;
  color: #999;

  p {
    text-align: justify;
  }
}

::v-deep .change-btn.disabled button {
  opacity: 1;
  border-color: lighten(@base-color, 20%);
  background: lighten(@base-color, 20%);
}
</style>
